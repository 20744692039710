import { createContext, useReducer} from 'react';
export const AccountContext = createContext();
export const accountReducer = (state, action) => {
    switch (action.type) {
        case 'SET_ACCOUNTS':
            return {
                accounts: action.payload
            };
        case 'CREATE_ACCOUNT':
            return {
                accounts: [action.payload,...state.accounts]
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
export const AccountsContextProvider = ({children})=>{
    const [state, dispatch] = useReducer(accountReducer, {
        accounts: []
    });
    return(
        <AccountContext.Provider value={{...state,dispatch}}>
            { children }
        </AccountContext.Provider>
    )
}