import { Link } from "react-router-dom";
import "boxicons";
import boss from "../images/boss-pic.jpg";
import "../style/home.css";
import MUI from "../images/logo.svg";
import C from "../images/C.png";
import CPP from "../images/cpp.svg";
import POS from "../images/POS.jpg";
import Dash from "../images/dashboard.jpg";
import { useEffect, useState } from "react";
import CIcon from "@coreui/icons-react";
import { cibLeetcode } from "@coreui/icons";
import homeFunc from "../libs/homepage";
import sendEmail from "../libs/sendEmail";

const Home = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  useEffect(() => {
    homeFunc();
  }, [homeFunc]);
  const [currentPageIndex, setCurrentPageIndex] = useState(1);
  useEffect(() => {
    const pages = document.querySelectorAll(".page-right");
    pages.forEach((page, index) => {
      if (page.classList.contains("turn")) {
        page.style.zIndex = 20 + index;
      } else {
        setTimeout(() => {
          page.style.zIndex = 20 - index;
        }, 500);
      }
    });
  }, [currentPageIndex]);
  const handleClick = (id, index) => {
    const pageID = document.getElementById(id);
    if (pageID.classList.contains("turn")) {
      pageID.classList.remove("turn");
    } else {
      pageID.classList.add("turn");
    }
    setCurrentPageIndex(index);
  };
  const contact = () => {
    const pages = document.querySelectorAll(".page-right");
    pages.forEach((page, index) => {
      setTimeout(() => {
        page.classList.add("turn");
        setTimeout(() => {
          page.style.zIndex = 20 + index;
        }, 500);
      }, (index + 1) * 200 + 100);
    });
  };
  const profile = () => {
    const pages = Array.from(
      document.querySelectorAll(".page-right")
    ).reverse();
    pages.forEach((page, index) => {
      setTimeout(() => {
        page.classList.remove("turn");
        setTimeout(() => {
          page.style.zIndex = 10 + index;
        }, 500);
      }, (index + 1) * 200 + 100);
    });
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    await sendEmail(name, email, text);
  };
  return (
    <div className="Pcontainer">
      <div className="Pwrapper">
        <div className="cover cover-left"></div>
        <div className="cover cover-right"></div>
        <div className="book">
          <div className="book-page page-left">
            <div className="profile-page">
              <img className="Ppic" src={boss} alt="" />
              <h1>Mr.Pongnawat</h1>
              <div className="social-media">
                <Link
                  to="https://www.facebook.com/pora.boss"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <box-icon name="facebook" type="logo"></box-icon>
                </Link>
                <Link
                  to="https://www.instagram.com/l3.ss_n?igsh=OGQ5ZDc2ODk2ZA=="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <box-icon name="instagram" type="logo"></box-icon>
                </Link>
                <Link
                  to="https://www.linkedin.com/in/pongnawat-ratpitak-b95916293/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <box-icon name="linkedin" type="logo"></box-icon>
                </Link>
                <Link
                  to="https://leetcode.com/IAmJuniorDev/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CIcon
                    className="special-icon"
                    icon={cibLeetcode}
                    size="xl"
                  />
                </Link>
              </div>
              <h3>Full-Stack Developer</h3>
              <div className="bt-box">
                <Link
                  to="https://drive.google.com/file/d/1AJIXyvTgm-5sai1YpQrqIUhLapPQI2y_/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bt"
                >
                  Download CV
                </Link>
                <Link className="bt contact-me" onClick={contact}>
                  Contract Me
                </Link>
              </div>
            </div>
          </div>
          {/* page 1 & 2 */}
          <div className="book-page page-right turn" id="turn-1">
            {/* page 1 (Education) */}
            <div className="page-front">
              <h1 className="title">Education</h1>
              <div className="workeduc-box">
                <div className="workeduc-content">
                  <div className="pcalendar-box">
                    <box-icon type="solid" name="calendar"></box-icon>
                    <span className="year">2014 - 2018</span>
                  </div>
                  <h3>Assumption colledge Sriracha</h3>
                  <p>
                    Completed high school education at Assumption College
                    Sriracha over four years, covering a broad range of subjects
                    and activities typical of secondary education.
                  </p>
                </div>
                <div className="workeduc-content">
                  <div className="pcalendar-box">
                    <box-icon type="solid" name="calendar"></box-icon>
                    <span className="year">2018 - 2020</span>
                  </div>
                  <h3>English at New Zealand</h3>
                  <p>
                    Spent two years in New Zealand focusing on English language
                    studies, immersing oneself in a native English-speaking
                    environment to enhance language proficiency and cultural
                    understanding.
                  </p>
                </div>
                <div className="workeduc-content">
                  <div className="pcalendar-box">
                    <box-icon type="solid" name="calendar"></box-icon>
                    <span className="year">2020 - 2024</span>
                  </div>
                  <h3>Chiang Mai Rajabhat University</h3>
                  <p>
                    Currently pursuing a degree in International Business
                    Management (IBM) at Chiang Mai Rajabhat University, gaining
                    theoretical knowledge and practical skills relevant to
                    global business operations and management.
                  </p>
                </div>
              </div>
              <span className="number-page">1</span>
              <span
                className="nextprev-btn"
                onClick={(e) =>
                  handleClick(e.target.getAttribute("data-page"), 1)
                }
              >
                <box-icon name="chevron-right" data-page="turn-1"></box-icon>
              </span>
            </div>
            {/* page 2 (my services) */}
            <div className="page-back">
              <h1 className="title">My Services</h1>
              <div className="services-box">
                <div className="services-content">
                  <box-icon name="code-alt"></box-icon>
                  <h3>MERN-Stack Web Development</h3>
                  <p>In order web development up on the order.</p>
                </div>
                <div className="services-content">
                  <box-icon name="mobile-alt"></box-icon>
                  <h3>Cross Platform Mobile application</h3>
                  <p>Development both Android and Apple Mobile application.</p>
                </div>
                <div className="services-content">
                  <box-icon name="line-chart"></box-icon>
                  <h3>Data Analysis</h3>
                  <p>
                    Able to arrange the data to be easy, then able to analyze
                    and forcase for next step.
                  </p>
                </div>
                <div className="services-content">
                  <box-icon name="search"></box-icon>
                  <h3>SEO</h3>
                  <p>
                    Professional web creating and make sure your web will be on
                    top when search.
                  </p>
                </div>
              </div>
              <span className="number-page">2</span>
              <span
                className="nextprev-btn back"
                onClick={(e) =>
                  handleClick(e.target.getAttribute("data-page"), 1)
                }
              >
                <box-icon name="chevron-left" data-page="turn-1"></box-icon>
              </span>
            </div>
          </div>
          {/* page 3 & 4 */}
          <div className="book-page page-right turn" id="turn-2">
            {/* page 3 (my skill) */}
            <div className="page-front">
              <h1 className="title">My Skill</h1>
              <div className="skills-box">
                <div className="skills-content">
                  <h3>Front-End</h3>
                  <div className="content">
                    <span>
                      <box-icon type="logo" name="html5"></box-icon>HTML
                    </span>
                    <span>
                      <box-icon name="react" type="logo"></box-icon>React
                    </span>
                    <span>
                      <box-icon name="react" type="logo"></box-icon>R-Native
                    </span>
                    <span>
                      <box-icon name="css3" type="logo"></box-icon>CSS
                    </span>
                    <span>
                      <box-icon name="bootstrap" type="logo"></box-icon>
                      Bootstrap
                    </span>
                    <span>
                      <img src={MUI} alt="" />
                      MUI
                    </span>
                  </div>
                </div>
                <div className="skills-content">
                  <h3>Back-End</h3>
                  <div className="content">
                    <span>
                      <box-icon name="python" type="logo"></box-icon>Python
                    </span>
                    <span>
                      <box-icon name="nodejs" type="logo"></box-icon>Node
                    </span>
                    <span>
                      <box-icon name="javascript" type="logo"></box-icon>JS
                    </span>
                    <span>
                      <img src={CPP} alt="" />
                      C++
                    </span>
                    <span>
                      <img src={C} alt="" />C
                    </span>
                    <span>
                      <box-icon name="mongodb" type="logo"></box-icon>MongoDB
                    </span>
                    <span>
                      <box-icon name="postgresql" type="logo"></box-icon>SQL
                    </span>
                  </div>
                </div>
              </div>
              <span className="number-page">3</span>
              <span
                className="nextprev-btn"
                onClick={(e) =>
                  handleClick(e.target.getAttribute("data-page"), 2)
                }
              >
                <box-icon name="chevron-right" data-page="turn-2"></box-icon>
              </span>
            </div>
            {/* page 4 (my skills) */}
            <div className="page-back">
              {/* page 4 my first project */}
              <h1 className="title">First Project</h1>
              <div className="portfolio-box">
                <div className="img-box">
                  <img src={POS} alt="" />
                </div>
                <div className="info-box">
                  <div className="info-title">
                    <h3>Coffee POS</h3>
                    <Link to="/coffeePOS">
                      Live Preview<box-icon name="link-external"></box-icon>
                    </Link>
                  </div>
                  <p>Tech Used:</p>
                  <p>
                    Printer size 54.44mm. , context, hook , useState ,
                    useEffect, ion-icon, uuidv4.
                  </p>
                </div>
                <div className="bt-box">
                  <Link
                    to="https://github.com/IAmJuniorDev/coffeePOS"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bt"
                  >
                    Source Code
                  </Link>
                  <Link to="/more-project" className="bt">
                    More Projects
                  </Link>
                </div>
              </div>
              <span className="number-page">4</span>
              <span
                className="nextprev-btn back"
                onClick={(e) =>
                  handleClick(e.target.getAttribute("data-page"), 2)
                }
              >
                <box-icon name="chevron-left" data-page="turn-2"></box-icon>
              </span>
            </div>
          </div>
          {/* page 5 & 6 */}
          <div className="book-page page-right turn" id="turn-3">
            {/* page 5 (latest project) */}
            <div className="page-front">
              <h1 className="title">Lastest Project</h1>
              <div className="portfolio-box">
                <div className="img-box">
                  <img src={Dash} alt="" />
                </div>
                <div className="info-box">
                  <div className="info-title">
                    <h3>Admin Dashboard</h3>
                    <Link to="/dashboard">
                      Live Preview<box-icon name="link-external"></box-icon>
                    </Link>
                  </div>
                  <p>Tech Used:</p>
                  <p>
                    react-redux, axios, @redux, @mui, @fullcalendar, @nivo,
                    MERN-stack.
                  </p>
                </div>
                <div className="bt-box">
                  <Link
                    to="https://github.com/IAmJuniorDev/Dashboard"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bt"
                  >
                    Source Code
                  </Link>
                  <Link to="/more-project" className="bt">
                    More Projects
                  </Link>
                </div>
              </div>
              <span className="number-page">5</span>
              <span
                className="nextprev-btn"
                onClick={(e) =>
                  handleClick(e.target.getAttribute("data-page"), 3)
                }
              >
                <box-icon name="chevron-right" data-page="turn-3"></box-icon>
              </span>
            </div>
            {/* page 6 (latest project) */}
            <div className="page-back">
              <h1 className="title">Contact Me!</h1>
              <div className="contact-box">
                <form action="#">
                  <input
                    type="text"
                    className="feild"
                    placeholder="Full Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  />
                  <input
                    type="email"
                    className="feild"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  <textarea
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="feild"
                    placeholder="Your Message"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                  ></textarea>
                  <input
                    type="submit"
                    className="bt"
                    value="Send Message"
                    onClick={sendMessage}
                  />
                </form>
              </div>
              <span className="number-page">6</span>
              <span
                className="nextprev-btn back"
                onClick={(e) =>
                  handleClick(e.target.getAttribute("data-page"), 3)
                }
              >
                <box-icon name="chevron-left" data-page="turn-3"></box-icon>
              </span>
              <div className="back-profile">
                <Link to="#" onClick={profile}>
                  <p>Profile</p>
                  <box-icon type="solid" name="user"></box-icon>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
