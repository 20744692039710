import { Link } from 'react-router-dom';

const Coupon = ()=>{
    return (
        <div className="coupon">
            <h1>This is Microtik coupon layout for printing</h1>
            <p>
                {`<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN"   "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd"> 
                    <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en" dir="ltr">
                        <head> 
                            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />  
                            <meta http-equiv="x-ua-compatible" content="IE=Edge;chrome=1" />  
                            <title>Vouchers</title> 
                            <style type="text/css">  
                                body {  
                                    font-size: 14px;  
                                    font-family: sans-serif; 
                                }  
                                div.voucher {  
                                    display: inline-block;  
                                    margin: 1px;  
                                    padding: 10px;  
                                    text-align: center;  
                                    border: 1px dashed #000; 
                                }  
                                div.voucher p {  
                                    margin: 0; 
                                }  
                                p.valid {    
                                    color: #000;  
                                } 
                                p.code {  
                                    font-weight: normal;  
                                    padding-top: 10px;  
                                    font-size: larger; 
                                }   
                            </style> 
                        </head>
                    <body onload="self.focus(); window.print()">
                        <div class="voucher">  
                            <div class="white">==== Vouchers ====</div> 
                            <p class="valid">Username : %u_username%</p>  
                            <p class="valid">Password : %u_password% </p> 
                            <p class="valid">Package :  %u_actualProfileName%</p>  
                        </div>
                    </body>
                    </html>
                    <p class="noprint" style="font-size: 10px">   ................ page break ................ </p> <p class="pagebreak">&nbsp;</p>`}
            </p>
            <Link to={'/'}><button>Back</button></Link>
        </div>
    )
}

export default Coupon;