import { useState } from "react";
import {useWorkoutsContext} from '../../hooks/useWorkoutsContext';
import { publicRequest } from "ecomRequest";
const WorkoutForm = ()=>{
    const {dispatch} =useWorkoutsContext();
    const [title,setTitle] = useState('');
    const [load,setLoad] = useState('');
    const [reps,setReps] = useState('');
    const [error,setError] = useState(null);
    const [emptyFields,setEmptyFields] = useState([]);

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const workout = {title,load,reps};

        const response = await publicRequest.post('/api/portfolio/workout',workout);
        console.log(response);
        const json = await response.data;
        if(response.status === 200){
            setTitle('');
            setLoad('');
            setReps('');
            setError(null);
            setEmptyFields([]);
            console.log('new value have been formed',json);
            dispatch({type:'CREATE_WORKOUTS',payload:json});
        }else{
            setError(json.error);
            setEmptyFields(json.emptyFields);
        }
    }

    return(
        <form className="create" onSubmit={handleSubmit}>
            <h3>Add a new workout</h3>
            <label>Exercise tittle</label>
            <input 
                type="text" 
                onChange={(e)=>setTitle(e.target.value)}
                value={title}
                className={Array.isArray(emptyFields) && emptyFields.includes('title') ? 'error' : ''}
            />
            <label>Load (in kg) :</label>
            <input 
                type="number" 
                onChange={(e)=>setLoad(e.target.value)}
                value={load}
                className={Array.isArray(emptyFields) && emptyFields.includes('load') ? 'error' : ''}
            />
            <label>Reps:</label>
            <input 
                type="number" 
                onChange={(e)=>setReps(e.target.value)}
                value={reps}
                className={Array.isArray(emptyFields) && emptyFields.includes('reps') ? 'error' : ''}
            />
            <button>Add workout</button>
            {error && <div className="error">{error}</div>}
        </form>
    )
}

export default WorkoutForm;