import ClockDetail from '../components/clock/clockDetail';
import '../style/clock.css';

const Clock = ()=>{
    return(
        <div className="clock-container">
            <ClockDetail />
        </div>
    )
}

export default Clock;