import { useEffect, useState } from "react";
import { useAccountsContext } from "../../hooks/useAccountContext";

const AccountReport = () => {
  const { accounts } = useAccountsContext(); // Retrieve accounts from context
  const [revenue, setRevenue] = useState(0);
  const [expense, setExpense] = useState(0);

  useEffect(() => {
    if (accounts.length > 0) {
      const amount = accounts.map((account) => account.amount);
      const income = amount.filter((e) => e > 0).reduce((total, e) => total += e, 0);
      const outcome = Math.abs(amount.filter((e) => e < 0).reduce((total, e) => total += e, 0));
      setRevenue(income);
      setExpense(outcome);
    }
  }, [accounts]);

  return (
    <div style={{ padding: "0 10px" }}>
      <h4>ยอดคงเหลือ (บาท)</h4>
      <h1>฿{revenue - expense}</h1>
      <div className="report-container">
        <div className="green">
          <h4>รายได้รวม</h4>
          <p className="report plus">฿{revenue}</p>
        </div>
        <div className="red">
          <h4>รายจ่ายรวม</h4>
          <p className="report minus">฿{expense}</p>
        </div>
      </div>
    </div>
  );
};

export default AccountReport;