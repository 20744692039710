import AccountDetail from "../components/accounts/accountDatail";
import AccountReport from "../components/accounts/accountReport";
import AccountForm from "components/accounts/accountForm"; 
import "../style/account.css";
import {useEffect} from 'react';
import { publicRequest } from "ecomRequest";
import { useAccountsContext } from "hooks/useAccountContext";
import { AccountsContextProvider } from "contexts/accountContext";

const Accounts = () => {
  const {accounts,dispatch} = useAccountsContext();
  useEffect(() => {
    const getAccount = async () => {
      try {
        const res = await publicRequest.get('/api/portfolio/accounts');
        const json = await res.data;
        if (res.status === 200) {
          dispatch({ type: 'SET_ACCOUNTS', payload: json });
        } else {
          console.error('Error fetching accounts:', res.statusText);
        }
      } catch (error) {
        console.error('Error fetching accounts:', error.message);
      }
    };
    getAccount();
  },[dispatch]);
  const design = { color: "red", textAlign: "center", fontSize: "1.5rem" };
  return (
    <div className="account-container">
      <div className="account">
        <h1 style={design}>บัญชีรายรับ - รายจ่าย</h1>
        <AccountReport />
        <AccountForm />
        <ul className="item-list">
          {[...accounts].map((account)=>(
            <AccountDetail key={account._id} account={account} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const Account = ()=>{
  return(
    <AccountsContextProvider>
      <Accounts />
    </AccountsContextProvider>
  )
}

export default Account;