import { useState } from 'react';
import { publicRequest } from 'ecomRequest';
import { useAccountsContext } from 'hooks/useAccountContext';

const AccountForm = () => {
    const { dispatch } = useAccountsContext();
    const [title, setTitle] = useState('');
    const [amount, setAmount] = useState('');
    const [error, setError] = useState(null);
    const [emptyFields, setEmptyFields] = useState([]);

    const saveItem = async (e) => {
        e.preventDefault();
        try {
            const response = await publicRequest.post('/api/portfolio/account', { title, amount });
            const json = await response.data;
            if (response.status === 200) {
                // Prepend the new account to the existing accounts list
                setTitle('');
                setAmount('');
                setError(null);
                setEmptyFields([]);
                dispatch({ type: 'CREATE_ACCOUNT', payload: json });
            } else {
                setError(json.error);
                setEmptyFields(json.emptyFields);
            }
        } catch (error) {
            console.error('Error saving account:', error);
            setError('An error occurred while saving the account.');
        }
    };

    return (
        <div className="formComponent">
            <form onSubmit={saveItem}>
                <div className="form-control">
                    <label>ชื่อรายการ</label>
                    <input
                        type="text"
                        placeholder="ระบุชื่อรายการของคุณ"
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        className={Array.isArray(emptyFields) && emptyFields.includes('title') ? 'error' : ''}
                    />
                </div>
                <div className="form-control">
                    <label>จำนวนเงิน</label>
                    <input
                        type="number"
                        placeholder="(+ รายรับ , - รายจ่าย)"
                        onChange={(e) => setAmount(e.target.value)}
                        value={amount}
                        className={Array.isArray(emptyFields) && emptyFields.includes('amount') ? 'error' : ''}
                    />
                </div>
                <div className="form-control">
                    <button type="submit" className="btn">เพิ่มข้อมูล</button>
                </div>
                {error && <div className='error'>{error}</div>}
            </form>
        </div>
    );
};

export default AccountForm;