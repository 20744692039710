import CountdownDetail from "../components/countdown/countdownDetail";
import CountdownRocket from "../components/countdown/countdownRocket";
import '../style/countdown.css';

const Countdown = ()=>{
    return(
        <div className="countdown-container">
            <CountdownDetail />
            <CountdownRocket />
        </div>
    )
}

export default Countdown;