import { publicRequest } from 'ecomRequest';

const sendEmail = async (name, email, text) => {
  const data = {
    to: 'pongnawat.ratpitak@gmail.com',
    subject: email,
    body: `Customer: ${name}\nEmail: ${email}\nText: ${text}`,
  };
  try {
    const response = await publicRequest.post('/api/portfolio/sendEmail',data);
    if(response.status===200){
      alert("Email has been sent successfully!");
      window.location.reload();
    }
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

export default sendEmail;