import { publicRequest } from 'ecomRequest';
import {useWorkoutsContext} from '../../hooks/useWorkoutsContext';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
const WorkoutDetails = ({workout})=>{
    const {dispatch} = useWorkoutsContext()
    const handleClick = async()=>{
        const response = await publicRequest.delete(`/api/portfolio/workout/`+workout._id)
        const json = await response.data;
        if(response.ok){
            dispatch({type:'DELETE_WORKOUTS',payload:json})
        }
    }
    return(
        <div className="workout-details">
            <h4>{workout.title}</h4>
            <p><strong>Load (KG.):{workout.load}</strong></p>
            <p><strong>Reps :{workout.reps}</strong></p>
            <p>{formatDistanceToNow(new Date(workout.createdAt),{addSuffix:true})}</p>
            <span className='material-symbols-outlined' onClick={handleClick}>delete</span>
        </div>
    )
}

export default WorkoutDetails;