const homeFunc = () => {
  const coverRight = document.querySelector(".cover.cover-right");
  const pages = Array.from(document.querySelectorAll(".page-right")).reverse();
  setTimeout(() => {
    coverRight.classList.add("turn");
    coverRight.style.zIndex = 0;
    pages.forEach((page, index) => {
      setTimeout(() => {
        page.classList.remove("turn");
        setTimeout(() => {
          page.style.zIndex = 10 + index;
        }, 500);
      }, (index+1)*200+100);
    });
  }, 2500);
};

export default homeFunc;
