import { useEffect }from 'react';
import WorkoutDetails from '../components/workouts/WorkoutDetails';
import WorkoutForm from '../components/workouts/WorkoutForm';
import {useWorkoutsContext} from '../hooks/useWorkoutsContext';
import { WorkoutsContextProvider } from '../contexts/WorkoutContext';
import '../style/workout.css';
import Navbar from 'layouts/navbar';
import { publicRequest } from 'ecomRequest';
const Workouts = ()=>{
    const {workouts,dispatch} = useWorkoutsContext();
    useEffect(()=>{
        const fetchWorkouts = async()=>{
            const response = await publicRequest.get('/api/portfolio/workouts');
            const json = await response.data;
            if(response.status===200){
                dispatch({type:'SET_WORKOUTS',payload:json})
            };
        };
        fetchWorkouts();
    },[dispatch])

    return(
            <div className="workoutHome">
                <div className="workouts">
                    { [...workouts].map((workout) =>(
                        <WorkoutDetails key={workout._id} workout={workout}/>
                    ))}   
                </div>
                <WorkoutForm/>
            </div>
    );
}

const Workout=()=>{
    return(
        <WorkoutsContextProvider>
            <Navbar />
            <Workouts />
        </WorkoutsContextProvider>
    )
}

export default Workout;