import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    quantity: 0,
    total: 0,
  },
  reducers: {
    addProduct: (state, action) => {
      state.quantity += 1;
      state.products.push(action.payload);
      const total = state.total += action.payload.price * action.payload.quantity;
      state.total = total;
    },
    increaseQuantity: (state, action) => {
      const { index } = action.payload;
      state.products[index].quantity += 1;
      state.total += state.products[index].price;
    },
    decreaseQuantity: (state, action) => {
      const { index } = action.payload;
      if (state.products[index].quantity > 1) {
        state.products[index].quantity -= 1;
        state.total -= state.products[index].price;
      }else {
        // Remove the product from the cart if its quantity is 1
        state.total -= state.products[index].price;
        state.products.splice(index,1);
        state.quantity -= 1;
      }
    },
  },
});

export const { addProduct,increaseQuantity,decreaseQuantity } = cartSlice.actions;
export default cartSlice.reducer;
