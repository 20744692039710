import EcomHome from "components/Ecom/EcomHome";

const EcomLayout = () => {
  return (
      <div className="EcomLayout-container">
        <EcomHome />
      </div>
  );
};

export default EcomLayout;
