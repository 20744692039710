import { Link } from "react-router-dom";
import ThailandTimeline from "../../libs/axios";
import { useEffect, useState } from "react";

const CountdownDetail = () => {
  const [time, setTime] = useState({
    hh: 0,
    mm: 0,
    ss: 0,
    days: 0,
    months: 0,
    years: 0,
    zones: 0,
  });
  useEffect(() => {
    const retrieveTime = async () => {
      try {
        const responseData = await ThailandTimeline();
        setTime(responseData);
      } catch (err) {
        console.error("Error", err);
      }
    };
    retrieveTime();
    const interval = setInterval(retrieveTime, 1000);
    return () => clearInterval(interval);
  }, []);
  const flip_time = () => {
    const targetTime = new Date("2033-02-16T23:59:59Z");
    const currentUTC = Date.UTC(
      time.years,
      time.months,
      time.days,
      time.hh,
      time.mm,
      time.ss
    );
    const timeDifference = targetTime - currentUTC;
    const totalSecondLeft = Math.floor(timeDifference / 1000);
    const SC = totalSecondLeft % 60;
    const totalMinuteLeft = Math.floor(totalSecondLeft / 60);
    const MN = totalMinuteLeft % 60;
    const totalHourLeft = Math.floor(totalMinuteLeft / 60);
    const HH = totalHourLeft % 24;
    const totalDayLeft = Math.floor(totalHourLeft / 24);
    const D = totalDayLeft % 30;
    const totalMonthLeft = Math.floor(totalDayLeft / 30);
    const M = totalMonthLeft % 12;
    const Y = Math.floor(totalMonthLeft / 12);
    const pad = (value) => value.toString().padStart(2, "0");
    return {
      Y: pad(Y),
      M: pad(M),
      D: pad(D),
      HH: pad(HH),
      MN: pad(MN),
      SC: pad(SC),
    };
  };
  return (
    <div className="countdown-content">
      <p>I'm organizing my life for change.</p>
      <h1>
        <span>Self-Manage</span> Time Left
      </h1>
      <div className="countdown">
        <div>
          <p id="year" className="items">
            {flip_time().Y}
          </p>
          <span>Years</span>
        </div>
        <p>:</p>
        <div>
          <p id="month" className="items">
            {flip_time().M}
          </p>
          <span>Months</span>
        </div>
        <p>:</p>
        <div>
          <p id="day" className="items">
            {flip_time().D}
          </p>
          <span>Days</span>
        </div>
        <p>:</p>
        <div>
          <p id="hour" className="items">
            {flip_time().HH}
          </p>
          <span>Hours</span>
        </div>
        <p>:</p>
        <div>
          <p id="minute" className="items">
            {flip_time().MN}
          </p>
          <span>Minutes</span>
        </div>
        <p>:</p>
        <div>
          <p id="second" className="items">
            {flip_time().SC}
          </p>
          <span>Seconds</span>
        </div>
      </div>
      <p className="nowadays"></p>
      <Link to="/" className="link">
        <button type="button">
          <ion-icon className="icon" name="caret-back-outline"></ion-icon>Back
        </button>
      </Link>
    </div>
  );
};

export default CountdownDetail;
