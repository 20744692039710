import Announcement from "../../utils/Ecom/announcement";
import Categories from "../../utils/Ecom/categories";
import Footer from "../../utils/Ecom/footer";
import Navbar from "../../utils/Ecom/navbar";
import Newsletter from "../../utils/Ecom/newsletter";
import Products from "../../utils/Ecom/products";
import Slider from "../../utils/Ecom/slider";

const EcomHome = () => {
  return (
    <div>
      <Announcement />
      <Navbar />
      <Slider />
      <Categories />
      <Products/>
      <Newsletter/>
      <Footer/>
    </div>
  );
};

export default EcomHome;