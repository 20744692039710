import { loginFailure, loginStart, loginSuccess } from "./userRedux";
import { publicRequest } from "ecomRequest";

export const login = async (dispatch,user)=>{
  dispatch(loginStart());
  try{
    const res = await publicRequest.post("/api/portfolio/ecom/login",user);
    dispatch(loginSuccess(res.data));
  }catch(err){
    dispatch(loginFailure())
  }
}