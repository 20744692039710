import ThailandTimeline from '../../libs/axios';
import {useEffect,useState} from 'react';

const ClockDetail = ()=>{
    const [time,setTime] = useState({
        hh: 0,
        mm: 0,
        ss: 0,
        days: 0,
        months: 0,
        years: 0,
        zones: 0,
    })
    useEffect(()=>{
        const retrieveTime = async()=>{
            try{
                const responseData = await ThailandTimeline();
                setTime(responseData);
            }catch(err){
                console.error('Error',err);
            }
        }
        retrieveTime();
        const interval = setInterval(retrieveTime,1000);
        return ()=>clearInterval(interval);
    },[])
    const clockHand = () => {
        const hrStyle = {
          transform: `rotateZ(${(time.hh*30 + time.mm / 12)%360}deg)`,
        };
        const mnStyle = {
          transform: `rotateZ(${(time.mm*6)%360}deg)`,
        };
        const scStyle = {
          transform: `rotateZ(${time.ss*6 }deg)`,
        };
        return { hrStyle, mnStyle, scStyle };
    };
    const timeUpdate = ()=>{
        const HH = time.hh;
        const HH12 = HH%12||12;
        const MN = time.mm;
        const SC = time.ss;
        const pad = (value)=>value.toString().padStart(2,'0');
        return {HH:pad(HH12),MN:pad(MN),SC:pad(SC)};
    }
    return(
        <>
            <div className="clock-border">
                <div className="clock">
                    <div id="sc" className="circle" style={{ '--clr': '#04fc43', ...clockHand().scStyle  }}><i></i></div>
                    <div id="mn" className="circle circle2" style={{ '--clr': '#fee800', ...clockHand().mnStyle  }}><i></i></div>
                    <div id="hr" className="circle circle3" style={{ '--clr': '#ff2972', ...clockHand().hrStyle  }}><i></i></div>
                    {[...Array(12)].map((_, index) => (
                    <span key={index} style={{ '--i': index + 1 }}><b>{index + 1}</b></span>
                    ))}
                </div>
            </div>
            <div className="time">
                <h1 style={{color:'red'}}>{timeUpdate().HH} : {timeUpdate().MN} : {timeUpdate().SC}</h1>
            </div>
        </>
    )
}

export default ClockDetail;