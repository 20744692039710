

const EcomSuccess = () => {
  return (
    <div className='EcomSuccess-container'>
      <h1>Success</h1>
    </div>
  );
};

export default EcomSuccess;