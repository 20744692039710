import { Link } from "react-router-dom";
import "../style/moreProject.css";
import workout from '../images/workout.JPG';
import account from '../images/account.JPG';
import clock from '../images/clock.JPG';
import countdown from '../images/countdown.JPG';
import ecom from '../images/ecom.JPG';

const MoreProject = () => {
  return (
    <div className="MP-container">
      <Link to="/workout">
        <img src={workout} alt="" />
        <p>Workout</p>
      </Link>
      <Link to="/account">
        <img src={account} alt="" />
        <p>Account</p>
      </Link>
      <Link to="/clock">
        <img src={clock} alt="" />
        <p>Clock</p>
      </Link>
      <Link to="/countdown">
        <img src={countdown} alt="" />
        <p>Countdown</p>
      </Link>
      <Link to="/Ecom">
        <img src={ecom} alt="" />
        <p>Ecom</p>
      </Link>
      
    </div>
  );
};

export default MoreProject;
